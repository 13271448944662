// import { useSelector } from "react-redux";

// export const Acl= ()=>{
//     const profile = useSelector(state => state.session.profileDetails);
//     console.log(profile.permission);
//     // return false;
//     return false;//useSelector(state => state.session.profileDetails);

// }

export const acl_check = (permissions, aclStrings) => {
    // const [resource, action] = aclString.split('-');
    const aclArray = Array.isArray(aclStrings) ? aclStrings : [aclStrings];
    if(aclArray.indexOf('any')!==-1){
        return true;
    }
    return aclArray.some((aclString) => {
        // const [resource, action] = aclString.split('-');
        const lastIndex = aclString.lastIndexOf('-');
        // Extract substrings using the last index
        const resource = aclString.substring(0, lastIndex).trim();
        const action = aclString.substring(lastIndex + 1).trim();
        return permissions?.[resource]?.[action];
    });
  };


  export const isGlobalAdmin = (roleName)=>{
    if(roleName==='global_admin'){
        return true;
    }else{
        return false;
    }
    
  }
  export const isSalesRepAdmin = (roleName)=>{
    if(roleName==='sales_rep'){
        return true;
    }else{
        return false;
    }
    
  }
  export const isCompanyEmployee = (roleName)=>{
    if(roleName==='company_employee'){
        return true;
    }else{
        return false;
    }
    
  }
 export const isClinicAdmin = (roleName) => {
    if (roleName === 'clinic_admin') {
        return true;
    } else {
        return false;
    }
 }
 export const isCompanyAdmin = (roleName) => {
    if (roleName === 'company_admin') {
        return true;
    } else {
        return false;
    }
 }
 export const isOrganizationAdmin = (roleName) => {
    if (roleName === 'organization_admin') {
        return true;
    } else {
        return false;
    }
 }
 export const hasStandardPriceEditPermission=(roleName)=>{
    return roleName=='global_admin' || roleName=='organization_admin';
 }
 export const addContractPricingPermission=(roleName)=>{
    
    return roleName=='sales_rep' || roleName=='organization_admin';
 }
