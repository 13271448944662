import React from "react";
import CompanyContainer from "../containers/CompanyContainer";
import EmployeeContainer from "../containers/EmployeeContainer";
import Appointments from "../containers/AppointmentsContainer";
import Dashboard from "../containers/DashboardContainer";
import { ClinicsLocationContainer } from "../containers/ClinicLocationContainer/index";
import EmployeeVisitContainer from "../containers/EmployeeVisitContainer";
import ResultContainer from "../containers/ResultContainer";
import WhiteBoardContainer from "../containers/WhiteBoardContainer";
import PERMISSIONS from "../utils/AclPermissions";
const WorkCompInsuranceContainer = React.lazy(() => import("../containers/WorkCompInsuranceContainer"));
const User = React.lazy(() => import("../containers/UserContainer"));
const Roles = React.lazy(() => import("../containers/RolesContainer"));
const Unauthorized = React.lazy(() => import("./Unauthorized"));
const ClinicContainer = React.lazy(() => import("../containers/ClinicContainer"));
const organisationContainer = React.lazy(() => import("../containers/OrganisationContainer"));
const Profile = React.lazy(() => import("../containers/ProfileContainer/Profile"));
const ChangePassword = React.lazy(() => import("../containers/ProfileContainer/ChangePassword"));
const InsuranceContainer = React.lazy(() => import("../containers/InsuranceContainer"));
const DivisionContainer = React.lazy(() => import("../containers/DivisionContainer"));
const LookupContainer = React.lazy(() => import("../containers/LookupContainer"));
const ActivitiesContainer = React.lazy(() => import("../containers/ActivitiesContainer"));
const PricingContainer = React.lazy(() => import("../containers/PricingContainer"));
const ContractApprovalContainer = React.lazy(() => import("../containers/ContractApprovalContainer"));
const BasicPhysicalContainer = React.lazy(() => import("../containers/BasicPhysicalContainer"));
const ProviderContainer = React.lazy(() => import("../containers/ProviderContainer"));
const CPTContainer = React.lazy(() => import("../containers/CPTContainer"));
const LaboratoryContainer = React.lazy(() => import("../containers/LaboratoryContainer"));
const LabContainer = React.lazy(() => import("../containers/LabContainer"));
const ConsentFormContainer = React.lazy(() => import("../containers/ConsentFormContainer"));
const VaccineAdministrationContainer = React.lazy(() => import("../containers/ActivitiesContainer/VaccineAdministrationContainer"));
const ReportsContainer = React.lazy(() => import("../containers/ReportsContainer"));
const CSVContainer = React.lazy(() => import("../containers/CSVContainer"));
const EzReaderContainer = React.lazy(() => import("../containers/EzReaderContainer"));
const NotificationContainer = React.lazy(() => import("../containers/NotificationContainer"));
const ConfigurationContainer = React.lazy(() => import("../containers/ConfigurationContainer"));
const HL7Container = React.lazy(() => import("../containers/HL7Container"));

const defaultConfig = {
  layoutType: "App",
  routeType: "Auth",
};
const PublicRoutes = [
  {
    path: "/",
    exact: true,
    element: Dashboard,
    acl: ["any"],
    ...defaultConfig,
    // Add more properties as needed
  },
  {
    path: "/unauthorized",
    exact: true,
    element: Unauthorized,
    acl: ["any"],
    ...defaultConfig,
    // Add more properties as needed
  },
  {
    path: "/dashboard",
    exact: true,
    element: Dashboard,
    ...defaultConfig,
    acl: [PERMISSIONS.DASHBOARD.LIST],
    // Add more properties as needed
  },
  {
    path: "/users/*",
    exact: true,
    element: User,
    acl: ["user-list", "user-edit"],
    ...defaultConfig,
  },
  {
    path: "/roles/*",
    exact: true,
    element: Roles,
    acl: ["role-list", "role-edit"],
    ...defaultConfig,
  },
  {
    path: "/labs/*",
    exact: true,
    element: LabContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "/clinics/*",
    exact: true,
    element: ClinicContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
    // Add more public routes as needed
  },
  {
    path: "/providers/*",
    exact: true,
    element: ProviderContainer,
    acl: ["provider-list"],
    ...defaultConfig,
  },
  {
    path: "/clincs-department/*",
    exact: true,
    element: ClinicsLocationContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "/organisation/*",
    exact: true,
    element: organisationContainer,
    acl: ["organization-list"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "/profile",
    exact: true,
    element: Profile,
      acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "/change-password",
    exact: true,
    element: ChangePassword,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/company/*",
    exact: true,
    element: CompanyContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/employee/*",
    exact: true,
    element: EmployeeContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/visits/*",
    exact: true,
    element: EmployeeVisitContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/white-board/*",
    exact: true,
    element: WhiteBoardContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/appointments/*",
    exact: true,
    element: Appointments,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/insurance/*",
    exact: true,
    element: InsuranceContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/division/*",
    exact: true,
    element: DivisionContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/lookup/*",
    exact: true,
    element: LookupContainer,
      acl: ["any"],
    ...defaultConfig,
  },
 
  {
    path: "/activities/*",
    exact: true,
    element: ActivitiesContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/pricing/*",
    exact: true,
    element: PricingContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/contract/*",
    exact: true,
    element: ContractApprovalContainer,
      acl: ["any"],
    ...defaultConfig,
  },

  {
    path: "/consentForm/*",
    exact: true,
    element: ConsentFormContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  
  {
    path: "/basic-physical/*",
    exact: true,
    element: BasicPhysicalContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/cpt-code/*",
    exact: true,
    element: CPTContainer,
      acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "/laboratory/*",
    exact: true,
    element: LaboratoryContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "/vaccine-administration/*",
    exact: true,
    element: VaccineAdministrationContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "result/*",
    exact: true,
    element: ResultContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "reports/*",
    exact: true,
    element:ReportsContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "csv-import/*",
    exact: true,
    element: CSVContainer,
    acl: ["any"],
    // acl:['role-list','role-edit'],
    ...defaultConfig,
  },
  {
    path: "failed-ez-reader/*",
    exact: true,
    element: EzReaderContainer,
    acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "notification-log/*",
    exact: true,
    element: NotificationContainer,
    acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "configure/*",
    exact: true,
    element: ConfigurationContainer,
    acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "hl7/*",
    exact: true,
    element: HL7Container,
    acl: ["any"],
    ...defaultConfig,
  },
  {
    path: "workcomp-insurance/*",
    exact: true,
    element: WorkCompInsuranceContainer,
    acl: ['any'],
    ...defaultConfig,
  },
  
  
  
];

export default PublicRoutes;
