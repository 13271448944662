import React, { useCallback, useEffect, useState } from "react";
import Header from "./Header";
import Menus from "./Menus";
import { useSelector } from "react-redux";
import { isSet } from "../../../utils/commonUtils";
import { activeProfileDetails } from "../../../store/SessionSlice";
import SessionTimeout from "../../../hoc/SessionTimeout";
import PERMISSIONS from "../../../utils/AclPermissions";
import { useDispatch } from "react-redux";
import { clearCbState } from "../../../store/GlobalFilterSlice";
import NotificationMessage from "./NotificationMessage";
import { fetchNotificationStatus } from "../../../store/NotificationSlice";

const AppLayout = (props) => {
  const { children } = props;
  const [activeMenu, setActiveMenu] = useState('');
  const [mainMenusList,setMainMenusList]  = useState([]);
  const [configMenusList,setConfigMenusList] = useState([]);
  const dispatch = useDispatch();
  const [showNotification,setShowNotification]=useState(false);    
  let currentPath = window.location.pathname;
  
 
  const profileDetails = useSelector(activeProfileDetails);
  const reloadId = useSelector(state=>state.common.reloadKeyProps);
  useEffect(()=>{
    let MenusData = [];
    let Configures = [];
    if (isSet(profileDetails?.role,'') === "global_admin" || isSet(profileDetails?.is_multiple_organization,'') === true) {
      MenusData = [
        {
          title: "Organization",
          link: "/organisation",
          acl: ['organization-list'],
          iconType: "organization",
        },
        {
          title: "Organization Companies",
          link: "/company",
          acl: ['company-list'],
          iconType: "Company",
        },
        // {
        //   title: "Labs",
        //   link: "/labs",
        //   acl: ['result-list'],
        //   iconType: "Labs",
        // },
        // {
        //   title: "Results",
        //   // link: "/result/physical-drug-screens",
        //   acl: ['result-list'],
        //   iconType: "Results",
        //   attr:{
        //     'data-bs-toggle':"collapse",
        //     'data-bs-target':'#results-submenu1'
        //   },
        //   subMenuId:'results-submenu1',
        //   subMenu:[
        //     {
        //       title: "Vaccines",
        //       link: "/result/vaccine",
        //       acl: ['result-list'],
        //       iconType: "PatientChart",
        //     },
        //     {
        //       title: "Physicals & Drug Screens",
        //       link: "/result/physical-drug-screens",
        //       acl: ['result-list'],
        //       iconType: "PatientChart",
        //     },
        // ],
        // },
        {
          title: "Organization Chart",
          link: "/employee",
          acl: ['employee-list'],
          iconType: "PatientChart",
        },
      ];
      Configures = [
        { Title: "Users", link: "/users", acl: 'user-list' },
        { Title: "Role & Permissions", link: "/roles", acl: 'role-list' },
        { Title: "Clinics", link: "/clinics", acl: 'clinic-list' },
        { Title: "Providers", link: "/providers", acl: 'provider-list' },
        { Title: "Laboratory", link: "/laboratory", acl: 'lab-list' },
        { Title: "Clinic Department", link: '/clincs-department', acl: 'clinic-department-list' },
        { Title: "Insurance", link: '/insurance', acl: 'insurance-list' },
        { Title: "Work Comp Insurance", link: '/workcomp-insurance', acl: 'insurance-list' },
        { Title: "Company Divisions", link: "/division", acl: ['company-division-list'] },
        { Title: "Lookup", link: "/lookup", acl: ['lookup-list'] },
        { Title: "Basic Physical", link: "/basic-physical", acl: ['basic-physical-list'] },
        { Title: "Activities", link: "/activities", acl: ['activities-list'] },
        { Title: "Contract Pricing", link: "/contract/pricing", acl: ['activities-list'] },
        // { Title: "Contract Approvals", link: "/contract/approval", acl: ['activities-list'] },
        { Title: "CPT Code", link: "/cpt-code", acl: [PERMISSIONS.CPT.LIST] },
        { Title: "Consent Form", link: "/consentForm", acl: ['consent-list'] },
        { Title: "Vaccine Administration", link: "/vaccine-administration", acl: ['vaccine-administration-list'] },
        { Title: "CSV Imports", link: "/csv-import", acl: [PERMISSIONS.CSV_IMPORT.LIST] },
        { Title: "Failed EZ-Reader", link: "/failed-ez-reader", acl: [PERMISSIONS.VISITS.EDIT] },
        { Title: "Notification Log", link: "/notification-log", acl: [PERMISSIONS.VISITS.EDIT] },
        { Title: "SFTP Configuration", link: "/configure", acl: [PERMISSIONS.VISITS.EDIT] },
        { Title: "HL7 Log", link: "/hl7/log", acl: [PERMISSIONS.VISITS.EDIT] },
      ];
    }else {
      MenusData = [
        {
          title: "Dashboard",
          link: "/dashboard",
          acl: [PERMISSIONS.DASHBOARD.LIST] ,
          iconType: "DashboardIcon",
        },
        {
          title: "Companies",
          link: "/company",
          acl: ['company-list'],
          iconType: "Company",
        },
        {
          title: "Appointments",
          link: "/appointments",
          acl: ['appointment-list', 'employee-list'],
          iconType: "Schedule",
        },
        {
          title: "White Board",
          link: "/white-board",
          acl: ['employee-list'],
          iconType: "WhiteBoard",
        },
        {
          title: "Visits",
          link: "/visits",
          acl: ['visits-list'],
          iconType: "PatientVisit",
        },
        {
          title: "Results",
          // link: "/result/physical-drug-screens",
          acl: ['result-list'],
          iconType: "Results",
          attr:{
            'data-bs-toggle':"collapse",
            'data-bs-target':'#results-submenu'
          },
          subMenuId:'results-submenu',
          subMenu:[
            {
              title: "Labs",
              link: "/labs",
              acl: ['result-list'],
              iconType: "Labs",
            },
            {
              title: "Vaccines",
              link: "/result/vaccine",
              acl: ['result-list'],
              iconType: "PatientChart",
            },
            {
              title: "Physicals & Drug Screens",
              link: "/result/physical-drug-screens",
              acl: ['result-list'],
              iconType: "PatientChart",
            },
        ],
          
        },
        {
          title: "Chart",
          link: "/employee",
          acl: ['employee-list'],
          iconType: "PatientChart",
        },
        // {
        //   title: "Biller Report",
        //   // link: "/result/physical-drug-screens",
        //   acl: ['result-list'],
        //   iconType: "Results",
        //   attr:{
        //     'data-bs-toggle':"collapse",
        //     'data-bs-target':'#reports-submenu'
        //   },
        //   subMenuId:'reports-submenu',
        //   subMenu:[
        //     {
        //       title: "Lab Report",
        //       link: "/reports",
        //       acl: ['result-list'],
        //     },
        //     {
        //       title: "Diagnosis Report",
        //       link: "/reports",
        //       acl: ['result-list'],
        //     },
        // ],
          
        // },
        {
          title: "Billing",
          // link: `https://snapcollector.com/login/healthcare?user_id=${profileDetails.id}`,
          link:`https://snapcollector.com`,
          acl: [PERMISSIONS.INVOICE.LIST],
          iconType: "Billing",
        },
        {
          title: "Reports",
          link: "/reports",
          acl: [PERMISSIONS.VISITS.LIST],
          iconType: "Reports",
        },
      ];
      Configures = [
        { Title: "Users", link: "/users", acl: 'user-list' },
        { Title: "Role & Permissions", link: "/roles", acl: 'role-list' },
        { Title: "Clinics", link: "/clinics", acl: 'clinic-list' },
        { Title: "Providers", link: "/providers", acl: 'provider-list' },
        { Title: "Laboratory", link: "/laboratory", acl: 'lab-list' },
        { Title: "Clinic Department", link: '/clincs-department', acl: 'clinic-department-list' },
        { Title: "Insurance", link: '/insurance', acl: 'insurance-list' },
        { Title: "Company Divisions", link: "/division", acl: ['company-division-list'] },
        { Title: "Lookup", link: "/lookup", acl: ['lookup-list'] },
        { Title: "Basic Physical", link: "/basic-physical", acl: ['basic-physical-list'] },
        { Title: "Activities", link: "/activities", acl: ['activities-list'] },
        { Title: "Contract Pricing", link: "/contract/pricing", acl: ['activities-list'] },
        // { Title: "Contract Approvals", link: "/contract/approval", acl: ['activities-list'] },
        { Title: "Consent Form", link: "/consentForm", acl: ['consent-list'] },
        { Title: "Vaccine Administration", link: "/vaccine-administration", acl: ['vaccine-administration-list'] },
        { Title: "CSV Imports", link: "/csv-import", acl: [PERMISSIONS.CSV_IMPORT.LIST] },
        { Title: "Failed EZ-Reader", link: "/failed-ez-reader", acl: [PERMISSIONS.VISITS.EDIT] }, 
        { Title: "Notification Log", link: "/notification-log", acl: [PERMISSIONS.VISITS.EDIT] },
        { Title: "HL7 Log", link: "/hl7/log", acl: [PERMISSIONS.VISITS.EDIT] },


      ];
    } 
    // else if(isSet(profileDetails) && isSet(profileDetails?.role[0],'') === "company_employee"  && isSet(profileDetails?.is_multiple_organization,false) === false) {
    //   MenusData = [
        
    //     {
    //       title: "Appointments",
    //       link: "/appointments",
    //       acl: ['appointment-list', 'employee-list'],
    //       iconType: "Schedule",
    //     },
    //     {
    //       title: "Labs",
    //       link: "/labs",
    //       acl: ['result-list'],
    //       iconType: "Labs",
    //     },
    //     {
    //       title: "Results",
    //       acl: ['result-list'],
    //       iconType: "Results",
    //       attr:{
    //         'data-bs-toggle':"collapse",
    //         'data-bs-target':'#results-submenu'
    //       },
    //       subMenuId:'results-submenu',
    //       subMenu:[
    //         {
    //           title: "Vaccines",
    //           link: "/result/vaccine",
    //           acl: ['result-list'],
    //           iconType: "PatientChart",
    //         },
    //         {
    //           title: "Physicals & Drug Screens",
    //           link: "/result/physical-drug-screens",
    //           acl: ['result-list'],
    //           iconType: "PatientChart",
    //         },
    //       ],
          
    //     },
    //   ];
    //   Configures = [];
      
    // }
    setMainMenusList(MenusData)
    setConfigMenusList(Configures);
  },[profileDetails])
  
  useEffect(() => {
    const currentPath = window.location.pathname;
    let matchingMenu = mainMenusList.find(menu => 
      isSet(menu?.subMenu,false)==false 
        ? currentPath.startsWith(menu.link)
        : menu.subMenu.find(sub=>currentPath.startsWith(sub.link)) 
    );
    if (matchingMenu) {
      setActiveMenu(matchingMenu.title);
    } else {
      matchingMenu = configMenusList.find(menu => currentPath.startsWith(menu.link));
      if (matchingMenu) {
        setActiveMenu(matchingMenu.Title);
      }
    }
  }, [mainMenusList, configMenusList]);
  useEffect(()=>{    
    let matchingMenu = mainMenusList.find(menu => currentPath.startsWith(menu.link));
    if (matchingMenu) {
      setActiveMenu(matchingMenu.title);
    } else {
      matchingMenu = configMenusList.find(menu => currentPath.startsWith(menu.link));
      if (matchingMenu) {
        setActiveMenu(matchingMenu.Title);
      }
    }
  },[currentPath]);  
  const sideBarChangeHandler = useCallback(() => {
    if (document.body.classList.contains('show-sidebar')) {
      document.body.classList.remove('show-sidebar');
      // menuToggleElement.removeClass('active');
    } else {
      document.body.classList.add('show-sidebar');
    }
  }, []);

  useEffect(()=>{
    if (document.body.classList.contains('show-sidebar')) {
      document.body.classList.remove('show-sidebar');
    } 
    dispatch(clearCbState());
  },[activeMenu])

  const removeSideBar=()=>{
    // document.body.classList.remove('show-sidebar');
  };

  

  useEffect(() => {
    const handleMouseUp = (e) => {
      const container = document.querySelector(".sidebar");
      const linkElement = document.querySelector(".js-menu-toggle");

      // Check if the clicked element is the link
      if (!container.contains(e.target) && isSet(linkElement) && !linkElement.contains(e.target)) {
        if (document.body.classList.contains('show-sidebar')) {
          document.body.classList.remove('show-sidebar');
          // Optionally, you can uncomment the line below if you have a menuToggleElement.
          // menuToggleElement.classList.remove('active');
        }
      }
    };

    document.addEventListener('mouseup', handleMouseUp);
    
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
    
    
  }, []);

 useEffect(()=>{
  document.title = 'Snap HealthCare | '+isSet(activeMenu,'Home');
  dispatch(fetchNotificationStatus());
 },[activeMenu]);

 const notificationToogleHandler=()=>{
  setShowNotification(!showNotification)
 }

  return (
    <>
    <SessionTimeout timeout={process.env.REACT_APP_SESSION_TIMEOUT} />
    <div className={""}>
      <div id="wrapper">
        <Menus
          MenusData={mainMenusList}
          Configures={configMenusList}
          setActiveMenu={setActiveMenu}
          currentPath={currentPath}
          activeMenu={activeMenu}
          sideBarChangeHandler={sideBarChangeHandler}
          removeSideBar={removeSideBar}
        />
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            <Header activeMenu={activeMenu} notificationToogleHandler={notificationToogleHandler}/>
            <div className="container-fluid">
              <div className="sh_mainPortview" id="sh_mainportal_view">
                <div className="sh_innerPortview" key={reloadId}>
                  {
                    showNotification?
                    <NotificationMessage
                    modleToogleHandler={notificationToogleHandler}
                    />
                    :
                    <></>
                  }
                 
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};


export default (AppLayout);
