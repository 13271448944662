import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import WithRouter from "../../../hoc/WithRouter";
import WithRoutify from "../../../hoc/WithRoutify";
import { useSelector } from "react-redux";
import { fetchNotificationStatus, notificationStatus } from "../../../store/NotificationSlice";
import { useNavigate } from 'react-router-dom';
import Buttons from "../../UI/Buttons";
import { useDispatch } from "react-redux";
const NotificationMessage = (props) => {
    const {modleToogleHandler}=props;
    const {notification,pendingCount} = useSelector(notificationStatus);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log('notifications=>',notification);
    const navigateUrl=(url)=>{
        navigate(url);
        modleToogleHandler();
    }
    const renderNotifications = () => {
        if (notification.length === 0) {
          return <p className="text-muted">No notifications available.</p>;
        }
      
        return notification.map((item, index) => (
            <div className="sh_cardBox mb-2">
                <div className="sh_cardBody">
                    <div className="d-flex justify-content-between">
                    <h5 className="fw-bold text-dark mb-0 font-weight-bold">{item.title}</h5>
                    <div>
                        <a className="btn btn-link" onClick={()=>navigateUrl(item.href)}>View Details</a>
                    </div>
                    </div>
                
                <small className="mb-0">{item.content} </small>
                </div>

            </div>

        ));
      };
      
    return (
        <>
            <Modal show={true} onHide={modleToogleHandler} dialogClassName='' keyboard={false}
                contentClassName='h-100'
                backdrop="static" size="md">
                <div className="sh_modalBox">
                    <Modal.Header
                    className=" p-2 d-flex align-items-center"
                    closeButton>
                        <div className="d-flex w-100 justify-content-between align-items-center">
                        <Modal.Title>
                            <h6 className="text-lg mb-0 font-weight-bold">
                               Messages
                            </h6>
 
                        </Modal.Title>
                        <Buttons
                  className="btn btn-icon btn-transparent-dark py-1 p-0"
                    iconType="Refresh"
                    iconColor="#5a5c69"
                    clickHandler={()=>dispatch(fetchNotificationStatus())}
                  />    </div>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="notification-list">{renderNotifications()}</div>
                    </Modal.Body>


                </div>
            </Modal>
        </>
    );
};
export default WithRouter(WithRoutify(NotificationMessage));