import React, { useEffect, useRef, useState } from "react";
import VisitsService from "../../services/VisitsService";
import Invoice from "./DashBoardComponent/Invoice";
import Physical from "./DashBoardComponent/Physical";
import DrugScreen from "./DashBoardComponent/DrugScreen";
import Lab from "./DashBoardComponent/Lab";
import MissingClaim from "./DashBoardComponent/MissingClaim";
import WorkRestrictions from "./DashBoardComponent/WorkRestrictions";
import DropDownService from "../../services/DropDownService";
import FormInputs from "../../components/UI/FormInputs";
import withReduxState from "../../hoc/wReduxState";
import WorkerCompCarrier from "./DashBoardComponent/WorkerCompCarrier";
import ComplianceMonitor from "./DashBoardComponent/ComplianceMonitor";
import Buttons from "../../components/UI/Buttons";
import Acl from "../../hoc/Acl";
import PERMISSIONS from "../../utils/AclPermissions";
import { acl_check } from "../../utils/aclUtils";

const DashboardContainer = (props) => {
  const [dashboardData, setDashBoardData] = useState({});
  const [companyOptions, setCompanyOption] = useState([{ label: "All Company", value: "" }]);
  const [clinicOptions, setClinicOptions] = useState([]);
  const hasInvoicePermission=acl_check(props.profile.permissions,PERMISSIONS.INVOICE.LIST);
  const hasVisitPermission=acl_check(props.profile.permissions,PERMISSIONS.VISITS.LIST);
  console.log('hasInvoicePermission',hasInvoicePermission);
  const resultClass=hasInvoicePermission?'col-md-6 col-6 py-2':'col-md-4 col-6 py-2';
  
  const [isLoading,setLoading]=useState(false);
  const [filter, setFilter] = useState(
    { last_updated_by: '' },
  );
  const [dateFilter,setDateFilter] = useState(
    {'from_date':''},
    {'to_date':''}
  );
  const filterDropdownRef = useRef(null);
  useEffect(() => {
    fetchDashBoard();
    fetchClinic();
  }, []);
  useEffect(() => {
    fetchCompany();
  }, [filter.clinic_id])
  const fetchDashBoard = async (param = filter,isRefresh=false) => {
    setLoading(true);
    let payload={};
    if(isRefresh){
       payload = {
        ...param,
        from_date: "",
        to_date: "",
      };
    }else{
       payload = {
        ...param,
        from_date: dateFilter.from_date,
        to_date: dateFilter.to_date,
      };
    }
    if(payload.clinic_id ===""){
      payload.company_id ="";
    }
    const response = await VisitsService.getDashBoard(payload);
    setDashBoardData(response);
    setLoading(false);

  }
  
  const setFilterDataEmpty = () => {
    setDateFilter({ from_date: '', to_date: '' });
  const updatedFilter = {
    ...filter,
    last_updated_by: '',
    clinic_id: '',
    company_id: ''
  };
  fetchDashBoard(updatedFilter,true);
  fetchClinic();
  setFilter(updatedFilter);
}
useEffect(() => {
  console.log('Updated dateFilter:', dateFilter);
}, [dateFilter]);
  const fetchCompany = async () => {
    let clinic_id = filter?.clinic_id;
    if (!clinic_id) return false;
    const response = await DropDownService.getAssignCompanyOptionsForSelfRegister(clinic_id);
    let companiesOptions = [{ label: "All Company", value: "" }];
    response.forEach((company) => {
      companiesOptions.push({
        label: company.name,
        value: company.company_uuid,
      });
    });
    setCompanyOption(companiesOptions);
  }
  const fetchClinic = async () => {
    let opt = [{ label: "All Clinic", value: "" }];
    let clinicResponse = await DropDownService.getClinicList(null);
    if (clinicResponse) {
      clinicResponse?.forEach((itm, ind) => {
        opt.push({ label: itm.name, value: itm.clinic_id });
      });
    }
    setClinicOptions(opt);
  }
  const filterChangeHandler = (val, name) => {
    const updatedFilter = { ...filter };
    if (val == '' && name == "clinic_id") {
      setCompanyOption([{ label: "All Company", value: "" }]);
    }
    if (name == "clinic_id" && val) {
      updatedFilter['company_id'] = '';
    }
    if (name=="last_updated_by") {
      setDateFilter({});
      closeDateFilterdropdown();
    }
    updatedFilter[name] = val;

    fetchDashBoard(updatedFilter);
    setFilter(updatedFilter);
  }
  const applyDateFilter = () => {
    const updatedFilter = {
      ...filter,
      'last_updated_by':'',
      from_date: dateFilter.from_date,
      to_date: dateFilter.to_date,
  };
  fetchDashBoard(updatedFilter);
  setFilter({...filter,['last_updated_by']:''});
  closeDateFilterdropdown();
  }
  const closeDateFilterdropdown=()=>{
    if (filterDropdownRef.current) {
      filterDropdownRef.current.classList.toggle('show');
    }
  }
  const getColumnClass = () => {
    if (!hasInvoicePermission && !hasVisitPermission) {
        return 'col-lg-12';
    }
    if (!hasInvoicePermission && hasVisitPermission) {
        return 'col-lg-8';
    }
    return 'col-lg-6';
};
  return (
    <>
      <div className=" h-100">
        <div className="sh_cardBox border-0">
          <div className="sh_cardHeader border-0 py-1" style={{ position: 'relative' }}>
            <div className="row justify-content-end " >
             <Acl acl={PERMISSIONS.CLINIC.LIST}>
              <div class="form-group dash_btn mx-1 mb-1 mx-1">
                <FormInputs
                  type="SelectList"
                  options={clinicOptions}
                  changeHandler={(value, e) =>
                    filterChangeHandler(value, 'clinic_id', e)
                  }
                  value={filter?.clinic_id}
                  name={'clinic_id'}
                />
              </div>
              <div class="form-group dash_btn mx-1 mb-1">
                <FormInputs
                  type="SelectList"
                  options={companyOptions}
                  changeHandler={(value, e) =>
                    filterChangeHandler(value, 'company_id', e)
                  }
                  value={filter?.company_id}
                  name={'company_id'}
                />
              </div>
              </Acl>
              <div className="form-group  mx-1 mb-0 mt-0">
                <a className="btn btn-primary my-1 dropdown dropdown-toggle" id="statusbtn" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Time Range
                </a>
                <div class="dropdown-menu shadow  " id="dash_timepickdtn" aria-labelledby="statusbtn" data-bs-popper="static"  onClick={(e) => e.stopPropagation()} ref={filterDropdownRef} >
                  <div class=" d-flex justify-content-between px-0 py-0" href="">

                    <div className="sh_cardBox mb-0 rounded-0" >
                      <div className="sh_cardHeader border-0">
                        <h6 className="text-md font-weight-bold mb-0">
                          Absolute time range
                        </h6>
                      </div>
                      <div className="sh_cardBody py-0 px-0">
                        <div className="dash_timepicker p-2" >
                          <div class="form-group ">
                            <label class="mb-0 text-sm opacity-75 text-truncate">From</label><div class="">
                              <FormInputs
                              type="Date"
                              changeHandler={(value, e)=>{setDateFilter({...dateFilter,['from_date']:value})}}
                              value={dateFilter?.from_date}
                              />
                            </div>
                          </div>
                          <div class="form-group ">
                            <label class="mb-0 text-sm opacity-75 text-truncate">To</label><div class="">
                            <FormInputs
                              type="Date"
                              changeHandler={(value, e)=>{setDateFilter({...dateFilter,['to_date']:value})}}
                              value={dateFilter?.to_date}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-center">
                            <a className="btn btn-primary" onClick={()=>applyDateFilter()}> Apply</a>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="sh_cardBox mb-0 rounded-0" >
                      <div className="sh_cardHeader border-0">
                        <h6 className="text-md font-weight-bold mb-0">
                          Search Quick Ranges
                        </h6>
                      </div>
                      <div className="sh_cardBody py-0 px-0">
                        <div className="form-group dash_timepicker p-2">

                          <FormInputs
                            type="SelectSearchList"
                            className="form-control form-control-solid"
                            options={getLastUpdatedByOption()}
                            changeHandler={(value, e) =>
                              filterChangeHandler(value, 'last_updated_by', e)
                            }
                            value={filter?.last_updated_by}
                          />

                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <a className="btn btn-primary mx-1" onClick={() => setFilterDataEmpty()}>
                 <span>
                  <Buttons
                  className="pr-1"
                    iconType="Refresh"
                  />
                  Refresh
                  </span>
                </a>
            </div>
              
            </div>
          </div>
          <div className="sh_cardBody py-0">
            <div className="row">
              <Acl acl={PERMISSIONS.INVOICE.LIST}>
              <div className="col-12 col-lg-3 pt-2 pr-0">
                    <Invoice data={dashboardData}
                      filter={filter}
                    />
                  </div>
              </Acl>
              <div className={`${getColumnClass()} col-12`}>
                <div className="sh_cardBox sh_dash_invoiceht border-0 mb-0">
                  <div className="sh_cardBody px-3 py-0" style={{ position: 'relative' }}>
                    <div className="row">
                    <div className={resultClass}>
                      <Physical
                        data={dashboardData}
                        filter={filter}
                        isLoading={isLoading}
                      />
                      </div>
                      <div className={resultClass}>
                        <DrugScreen
                          data={dashboardData}
                          filter={filter}
                          isLoading={isLoading}
                        />
                      </div>
                      <div className={resultClass}>
                        <Lab
                          data={dashboardData}
                          filter={filter}
                          isLoading={isLoading}
                        />
                      </div>
                      <div className={resultClass}>
                        <WorkerCompCarrier
                          data={dashboardData}
                          filter={filter}
                          isLoading={isLoading}
                        />
                      </div>

                      <div className={resultClass}>
                        <MissingClaim
                          data={dashboardData}
                          filter={filter}
                          isLoading={isLoading}
                        />
                      </div>
                      <div className={resultClass}>
                        <WorkRestrictions
                          data={dashboardData}
                          filter={filter}
                          isLoading={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <Acl acl={PERMISSIONS.VISITS.LIST}>
              <div className={` ${hasInvoicePermission?'col-lg-3':'col-lg-4'} col-12 pt-2 pl-0`}>
                <ComplianceMonitor/>
              </div>
              </Acl>
            </div>
          </div>
        </div>

      </div>
    </>
  );
  function getLastUpdatedByOption() {
    return [
      { label: "Last 5 Minutes", value: 'last_5_minutes' },
      { label: "Last 10 Minutes", value: 'last_10_minutes' },
      { label: "Last 15 Minutes", value: 'last_15_minutes' },
      { label: "Last 30 Minutes", value: 'last_30_minutes' },
      { label: "Last 1 Hour", value: 'last_1_hour' },
      { label: "Last 3 Hours", value: 'last_2_hours' },
      { label: "Last 6 Hours", value: 'last_6_hours' },
      { label: "Last 12 Hours", value: 'last_12_hours' },
      { label: "Last 24 Hours", value: 'last_24_hours' },
      { label: "Last 2 Days", value: 'last_2_days' },
      { label: "Last 7 Days", value: 'last_7_days' },
      { label: "Last 30 Days", value: 'last_30_days' },
      { label: "Last 90 Days", value: 'last_90_days' },
      { label: "Last 6 Months", value: 'last_6_months' },
      { label: "Last 1 Year", value: 'last_1_year' },
      { label: "Last 2 Years", value: 'last_2_years' },
      { label: "Last 5 Years", value: 'last_5_years' },
      { label: "Today", value: 'today' },
      { label: "Yesterday", value: 'yesterday' },
      { label: "Day Before Yesterday", value: 'day_before_yesterday' },
      { label: "This day last week", value: 'this_day_last_week' },
      { label: "Previous Week", value: 'previous_week' },
      { label: "Previous Month", value: 'previous_month' },
      { label: "This Week ", value: 'this_week' },
      { label: "This Month ", value: 'this_month' },
      { label: "This Year", value: 'this_year' },
      { label: "ALL", value: '' },
    ];
  };
};

export default withReduxState(DashboardContainer);


