import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormInputs from '../UI/FormInputs';
import { Modal } from "react-bootstrap";
import { isSet,isObject } from '../../utils/commonUtils';
import Buttons from "../../components/UI/Buttons";
import VisitsService from '../../services/VisitsService';
import LookupService from '../../services/LookupService';
import { VISIT_STATUS } from '../../utils/constant';
import WithRoutify from '../../hoc/WithRoutify';
import { FieldValidationFocus } from "../../utils/cssUtils";
import { useDispatch } from 'react-redux';
import { clearCbState } from '../../store/GlobalFilterSlice';
import ContractService from '../../services/ContractService';

const MiniPopup = (props) => {
  const { isOpen, onClose, visitId , fetchVisitsList ,selectedVisitStatus, type, contractId, fetchRecord, contractStatus} = props;
  const isGroupEdit=  visitId && (visitId=="ALL"||visitId.split(',').length>1);
  const modalTitle=isGroupEdit?`Visit Status Edit for ${visitId=="ALL"?props?.totalRecords:visitId.split(',').length} Record(s)`: (type == "Contract" ? "Update Active Status": "Update Visit Status");
  const [formData,setFormData] = useState({});
  const [validation,setValidation] = useState({});
  const [visitStatus,setVisitStatus] = useState({});
  const dispatch = useDispatch();
  let visitStatusArr = [{ label: visitStatus, value: visitStatus }];
  console.log('isGroupEdit',isGroupEdit,visitId.split(',').length);
  
  
  useEffect(()=>{
    if(type == "Contract"){
      setFormData({is_active:contractStatus});
        
    }else{
      setFormData({visit_status:selectedVisitStatus});
      fetchVisitStatus();
    }
    
  },[]);
  
  const fetchVisitStatus = async ()=>{
    try{
      const data = await LookupService.getLookupByKey(VISIT_STATUS);
      visitStatusArr = [{ label: '-Visit Status-', value: '' }];  
      data.forEach((item) => {
            visitStatusArr.push({
                        label: item.value,
                        value: item.value
                    });
            });
        setVisitStatus(visitStatusArr);
    }catch(e){
    }
  };

  const inputFields = [
    type === "Contract"
      ? {
          type: "SelectList",
          label: "Active Status",
          placeholder: "",
          options:[{'label':'Active',value:'1'},{label:'In-Active',value:'0'}],
          className: "form-control form-control-solid",
          name: "is_active",
          required: true,
        }
      : {
          type: "SelectList",
          label: "Visit Status",
          placeholder: "",
          options: visitStatus ? visitStatus : visitStatusArr,
          className: "form-control form-control-solid",
          name: "visit_status",
          required: true,
        },
  ];
  


  const changeHandler = (value, fieldName) => {
    const updateFormData = { ...formData };
    const updateValidation = { ...validation };
    updateFormData[fieldName] = value;
    updateValidation[fieldName] = '';
    setFormData(updateFormData);
    setValidation(updateValidation);
  };


  const updateStatus = async () => {
    let updateValidation = { ...validation };
    props.Loader(true);
    try {
      let data;
   if(type == "Contract"){
    data = await ContractService.updateActiveStatus(contractId,{is_active: formData.is_active});
  }else{
    
    const payload = {
        visit_status: formData.visit_status,
    };   
      if (isGroupEdit) {
        payload.group_edit=true;
        payload.visit_ids=visitId;
        dispatch(clearCbState());
        data = await VisitsService.updateVisitStatus(null,'visit_id',payload);    
      }
      else{
        data = await VisitsService.updateVisitStatus(null,visitId,payload);
      }
   }
   if (data.status && data.status === "ERROR") {
    if (isObject(data.data)) {
      for (let key in data.data) {
        updateValidation = {
          ...updateValidation,
          [key]: data.data[key].join(","),
        };
      }
    } else {
      props.Toast.error(isSet(data.data,'Something went wrong..'));
      props.Loader(false);
    }
    
    if (Object.keys(updateValidation).length > 0) {
      setValidation(updateValidation);
      FieldValidationFocus(inputFields, updateValidation);
      props.Loader(false);
      return;
    }
  } else {
    if(type == "Contract"){
      fetchRecord();
      props.Toast.success('Contract Active Status Updated Successfully');
    }else{
      fetchVisitsList();
      props.Toast.success('Visit Status Updated Successfully');
    }    
    props.Loader(false);
  }
  } catch (error) {
    props.Loader(false);
    props.Toast.error('Failed to update status');
  } 
    onClose();
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        dialogClassName="hResponsive modal-sm"
        keyboard={false}
        backdrop="static"
      >
        <div className="sh_modalBox">
          <Modal.Header closeButton>
            <Modal.Title>
              <h6 className="text-lg mb-0 font-weight-bold">{modalTitle}</h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {inputFields &&
                inputFields.map((field, index) => (
                  <div className={`col-12`} key={index}>
                    <div className="form-group">
                      <label className="text-gray-900 text-md opacity-75">
                        {field.label}
                      </label>
                      <FormInputs
                        id={isSet(field.id,field.name)}
                        changeHandler={(val, e) => changeHandler(val, field.name, e)}
                        value={formData[field.name]}
                        {...field}
                      />
                      {isSet(validation[field.name], "") !== "" ? (
                        <span className="text-danger m-1">
                          {isSet(validation[field.name], "")}
                        </span>
                      ) : ("")}
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Buttons
              type='Single'
              className="btn btn-primary"
              clickHandler={() => (updateStatus())}
              label='Submit'
              acl='any' />
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

MiniPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  visitId: PropTypes.string
};

export default WithRoutify(MiniPopup);
