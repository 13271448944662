import { wOrgIdMiddleware } from '../utils/commonUtils';
import { CREATED, OK } from '../utils/constant';
import http from './http';
const ContractService = {
  saveContractPricing: wOrgIdMiddleware(async (url, data) => {
    try {
      const response = await http.POST(`${url}pricing`, data);
      if (response.code==CREATED) {
        return response.data;
      }
      else{
        return response;
      }  
    } catch (error) {
        return null;
    }
  }),
  saveApprovalSignature: wOrgIdMiddleware(async (url, data) => {
    try {
      const response = await http.POST(`${url}pricing/approval`, data);
      return response;
    } catch (error) {
        return null;
    }
  }),
  updateActiveStatus : wOrgIdMiddleware(async (url, id, data) => {
    try {
      console.log('data', url, id, data);
      const response = await http.PUT(`${url}pricing/${id}`, data);
      if (response.status === 200 && response.data) {
        console.log('data no error');
        const resData = response.data;
        return resData;
      } else {
        return response;
      }
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching contract:', error);
      throw error;
    }
  }),

  getContractsList: wOrgIdMiddleware(async (url, query={}) => {
    const queryString = new URLSearchParams(query).toString();
    try {
      const response = await http.GET(`${url}pricing?${queryString}`);
      return response.data;
    } catch (error) {
        return null;
    }
  },true),
  // fetchClinicListByDataSharing:wOrgIdMiddleware(async (url) => {
  //   try {
  //     const response = await http.GET(`${url}clinicDropdown`);
  //     return response.data;
  //   } catch (error) {
  //       return null;
  //   }
  // }),
  fetchContractDetails: wOrgIdMiddleware(async (url, contractId) => {
    try {
      const response = await http.GET(`${url}pricing/${contractId}`);
      return response.data;
    } catch (error) {
        return null;
    }
  }),
  deleteContract: wOrgIdMiddleware(async (url, contractId) => {
    try {
      const response = await http.DELETE(`${url}pricing/${contractId}`);
      return response;
    } catch (error) {
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }
      console.error('Error fetching contract:', error);
      throw error;
    }
  },true),
  copyContract: wOrgIdMiddleware(async (url, payload) => {
    try {
      const response = await http.POST(`${url}copy-contract`,payload);
      return response.data;
    } catch (error) {
        return null;
    }
  }),
  getContractLog: wOrgIdMiddleware(async (url, query={}) => {
    const queryString = new URLSearchParams(query).toString();
    try {
      const response = await http.GET(`${url}contract/log?${queryString}`);
      return response.data;
    } catch (error) {
        return null;
    }
  },true),
  getContractDetailsPdf: wOrgIdMiddleware(async (url, contractId)=> {
    try {
      const response = await http.GET(`${url}contract-pdf/${contractId}`);
      console.log('response', response);
      if (response.code === OK) {
        return response.data;
      } 
    }catch (error) {
        return null;
    }
  }),

};

export default ContractService;
