import { isSet, wOrgIdMiddleware } from '../utils/commonUtils';
import { OK } from '../utils/constant';
// import OrganizationService from './OrganizationService';
import http from './http';


const DropDownService = {
  getGlobalSearchDropDown: wOrgIdMiddleware(async (url, query) => {
    const queryString = new URLSearchParams(query).toString();
    try {
      const response = await http.GET(`${url}globalSearch?${queryString}`);
      return response.data;
    } catch (error) {
      // console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

    }
  }, true),

    getOrganizationDropDownList:async(query='')=>{
        try {
          const queryString = new URLSearchParams(query).toString();
            const response = await http.GET(`get-dropdown/organizations?${queryString}`);
            
              if (response.code===OK) {
                return response.data;
              }
            
          } catch (error) {
            console.error('Error fetching user:', error);
            if(error.status){
              const resData = error.data;
              if(resData.code && resData.data){
                  return resData;
              }
            }
            
            // throw error;
          } 
    },
  getCompanies: wOrgIdMiddleware(async (url) => {
    try {
    //   const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`${url}get-dropdown/company`);
      
        if (response.code === OK) {
          return response.data;
        }
      
    } catch (error) {
      // console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

    }
  },true),

  getLabsforClinic:wOrgIdMiddleware(async(url,query) => {
    const queryString = new URLSearchParams(query).toString();
    try {
      const response = await http.GET(`${url}get-dropdown/labsForClinic?${queryString}`);
            
        if (response.code === OK) {
          return response.data;
        }
 
    } catch (error) {
      // console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

    }
  },true),
  getAssignCompanyOptionsForSelfRegister: async (clinic_id) => {
    try {
    //   const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`get-dropdown/assign_company/${clinic_id}`);
      console.log('company options for self register',response);
        if (response.code === OK) {
          return response.data;
        }
      
    } catch (error) {
      // console.error('Error fetching company:', error);
      if (error.status) {
        const resData = error.data;
        if (resData.code && resData.data) {
          return resData;
        }
      }

    }
  },
  
  getClinicList: wOrgIdMiddleware(async (url) => {
    try {
      
      const response = await http.GET(`${url}get-dropdown/clinic`);
      
        if(response.code===200 && response.data){
          return response.data;
        }
      
      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  },true),
  getClinicListForSelfRegistration: async (value="") => {
    try {
      const response = await http.GET(`get-dropdown-self-registration/clinics?value=${value}`);
      // console.log('drop self regs',response);
        if(response.code===200 && response.data){
          return response.data;
        }
      
      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  },
  getClinicLocationsForSelfRegistration: async (clinic_id) => {
    try {
      const response = await http.GET(`get-dropdown-self-registration/clinicLocation/${clinic_id}`);
      
        if(response.code===200 && response.data){
          return response.data;
        }
      
      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  },

  getInsuranceDropDownSelfRegisteration: async () => {
    try {
      const response = await http.GET(`get-dropdown-self-registration/insurance`);
      if (response.code === OK) {
        return response;
      }
      else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching Insurance:', error);
    }
  },
  
  getLabsList: wOrgIdMiddleware(async (url) => {
    
    try {

      const response = await http.GET(`${url}get-dropdown/labs`);
      
        if (response.code === 200 && response.data) {
          return response.data;
        }
      
      return null;
    } catch (error) {
      // console.error('Error fetching user:', error);
      throw error;
    }
  }, true),
  getProvidersDropdownList: wOrgIdMiddleware(async (url, query = {}) => {
    // const queryString = new URLSearchParams(query).toString();
    try {
        // const orgId       = OrganizationService.getOrgId();
        const response = await http.GET(`${url}get-dropdown/providers`);
        
            if (response.code === OK) {
                return response.data;
            }
        
    } catch (error) {
        // console.error('Error fetching provider:', error);
        if (error.status) {
            const resData = error.data;
            if (resData.code && resData.data) {
                return resData;
            }
        }

        // throw error;
    }
},true),
getActivityDropdownList: wOrgIdMiddleware(async (url,query={}) => {
  const queryString = new URLSearchParams(query).toString();
  try {
      const response = await http.GET(`${url}get-dropdown/activity?${queryString}`);
      if (response.code === OK) {
          return response.data;
      }
      
  } catch (error) {
      // console.error('Error fetching provider:', error);
      if (error.status) {
          const resData = error.data;
          if (resData.code && resData.data) {
              return resData;
          }
      }

      // throw error;
  }
},true),
getActivityDropdownListforPricing: wOrgIdMiddleware(async (url,query={}) => {
  try {
    const queryString = new URLSearchParams(query).toString();
    const response = await http.GET(`${url}get-dropdown/pricing-activities?${queryString}`);
      if (response.code === OK) {
          return response.data;
      }
      
  } catch (error) {
      // console.error('Error fetching provider:', error);
      if (error.status) {
          const resData = error.data;
          if (resData.code && resData.data) {
              return resData;
          }
      }

      // throw error;
  }
},true),
getTimeZoneDropdownList: async (url, query = {}) => {
  try {
      // const queryString = new URLSearchParams(query).toString();
      const response = await http.GET(`/organizations/org_id/get-dropdown/timezone`);
      let options=[{label:'--Select Timezone--',value:''}];
      
          if (response.code === OK) {
            let dropdownOptions=response.data;
            if (dropdownOptions) {
             isSet(dropdownOptions,[]).forEach((item,index) => {
                options.push({label:item.time_zone_name,value:item.time_zone}); 
              });
            }
              return options;
          }
      
  } catch (error) {
      // console.error('Error fetching provider:', error);
      if (error.status) {
          const resData = error.data;
          if (resData.code && resData.data) {
              return resData;
          }
      }

      // throw error;
  }
},
getBasicPhysicalDropdownList: wOrgIdMiddleware(async (url) => {
  try {
    
    const response = await http.GET(`${url}get-dropdown/basic-physicals`);
    
      if(response.code===200 && response.data){
        return response.data;
      }
    
    return null;
  } catch (error) {
    console.error('Error fetching user:', error);
    // throw error;
  }
},true),
getClinicDropDownListByCompany: wOrgIdMiddleware(async (url,query={}) => {
  try {
    const queryString = new URLSearchParams(query).toString();
    const response = await http.GET(`${url}get-dropdown/clincsByCompany?${queryString}`);

      if(response.code===200 && response.data){
        return response.data;
      }

    return null;
  } catch (error) {
    console.error('Error fetching user:', error);
    // throw error;
  }
},true),
  getEmployeeList: wOrgIdMiddleware(async (url) => {
    try {
      const response = await http.GET(`${url}get-dropdown/employee`);
      if (response.code === 200 && response.data) {
        return response.data;
      }

      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  }, true),
  getSftpConfigList: wOrgIdMiddleware(async (url) => {
    try {
      const response = await http.GET(`${url}get-dropdown/sftp-config`);
      if (response.code === 200 && response.data) {
        return response.data;
      }

      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  }),
  getWorkCompDropDown: wOrgIdMiddleware(async (url) => {
    try {
      const response = await http.GET(`${url}get-dropdown/workcomp`);
      if (response.code === 200 && response.data) {
        return response.data;
      }

      return null;
    } catch (error) {
      console.error('Error fetching user:', error);
      // throw error;
    }
  }),
};

export default DropDownService;
