import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../services/UserService';

export const fetchNotificationStatus = createAsyncThunk(
  'api/fetchNotificationStatus',
  async () => {
    const response = await UserService.getUsersNotifications();
    return response; 
  }
);

const NotificationSlice = createSlice({
  name: 'api',
  initialState: {
    data: { notification: [], pendingCount: 0 },
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationStatus.fulfilled, (state, action) => {
        state.loading = false;
        const { notifications, pendingCount } = action.payload;        
        state.data.notification = notifications || [];
        state.data.pendingCount = pendingCount || 0;
      })
      .addCase(fetchNotificationStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const notificationStatus = (state) => state.notification.data;
// export const selectApiLoading = (state) => state.api.loading;
// export const selectApiError = (state) => state.api.error;

export default NotificationSlice.reducer;
