export const isLaboratoryVisitCategory=(visit_category='')=>{
    return visit_category==='Employer Services - Labs' ||visit_category==='Urgent Care - Labs Only';
}
export const isPhysicalVisitCategory=(visit_category='')=>{
    return visit_category==='Employer Services - Physicals';
}
export const getStatusAndColor = (resultStatus = 'pending') => {
    let statusClr = 'blue';
    let status = resultStatus;

    // Adjust status based on specific resultStatus values
    if (resultStatus === 'Completed/Administrated') {
        status = 'passed';
    } else if (resultStatus === 'Ordered') {
        status = 'pending';
    } else if (resultStatus === 'The subject Passed the fit test') {
        status = 'passed';
    } else if (resultStatus === 'The subject Failed the fit test') {
        status = 'failed';
    }

    // Determine color based on the updated status
    if (status.toLowerCase() === 'passed') {
        statusClr = 'green';
    } else if (status.toLowerCase() === 'failed') {
        statusClr = 'red';
    }

    return { statusClr, status };
};
export const getBillingStatusColor = (billingSts = '') => {
    billingSts = billingSts.toLowerCase();
    if (billingSts == 'pending') {
        return "#146dc7";
    }
    else if (billingSts == 'no charge') {
        return "#7e48a0";
    }
    else if (billingSts=='ready to bill') {
        return  "#caa850"
    }
    else if (billingSts=='billing hold') {
        return  "#cb352e";
    }
    else if (billingSts=='billed') {
        return  "#118a2e";
    }
    return null;
}
export const getActiveIds = (tableRecords) => {
    const { tableRows } = tableRecords;
    const activeIds = tableRows.data
        .filter((item) => item.data && item.data.isActive === 1)
        .map((item) => item.rowId)
        .join(',');
    return activeIds;
}
export const anyCheckboxSelected = (tableRecords) => {
    const { tableRows } = tableRecords;
    if (!tableRows || !Array.isArray(tableRows.data)) {
      return false;
    }

    return tableRows.data.some(item => item.data?.isActive === 1 || item.data?.isActive === true);
  }
export const getVisitStatusIcon = (sts = "New") => {
    const visitStatus = sts.toLowerCase();
    const statusGroups = {
        blue: ['New', 'Waiting Room', 'Checked In', 'Active'],
        green: ['Ready for Discharge', 'Discharge', 'Discharge Desk'],
        red: ['Cancelled', 'No Show', 'In Active'],
    };
    for (const [color, statuses] of Object.entries(statusGroups)) {
        if (statuses.some(status => status.toLowerCase() === visitStatus)) {
            return color;
        }
    }
    return 'blue';
};