import React, { useState, useEffect } from 'react';
import GlobalSearchInput from './GlobalSearchInput';
import EmployeeVisitFilter from './Filters/EmployeeVisitFilter';
import { TransitionGroup } from 'react-transition-group';
import './globalSearch.css';
import withReduxState from '../../../../hoc/wReduxState';
import { useDispatch } from 'react-redux';
import { updateGlobalFilter } from '../../../../store/GlobalFilterSlice';
const WhiteBoardFilter = React.lazy(() => import('./Filters/WhiteBoardFilter'));
const LabResultFilter = React.lazy(() => import('./Filters/LabResultFilter'));
const VaccineResultFilter = React.lazy(() => import('./Filters/VaccineResultFilter'));
const PhysicalsResultFilter = React.lazy(() => import('./Filters/PhysicalsResultFilter'));
const EmployeeChartFilter = React.lazy(() => import('./Filters/EmployeeChartFilter'));
const GlobalSearch = (props) => {
    const { pathname,globalFilter} = props; 
    const [isOpen, setIsOpen] = useState(false);
    const [activeMenu,setActiveMenu]=useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filterData,setFilterData]=useState({});
    const dispatch = useDispatch();   
    const toggleSearch = () => {
        setIsOpen(!isOpen);
    };        
    useEffect(()=>{ 
        //for set state intial state
        if (globalFilter) {
            setSearchTerm(globalFilter?.[activeMenu]?.searchName ||'');
            setFilterData(globalFilter?.[activeMenu] ||{});
        }
    },[globalFilter,activeMenu]);
    //     useEffect(()=>{ 
    //     //for set state intial state
    //     if (globalFilter) {
    //         setSearchTerm('');
    //         setFilterData({});
    //         dispatch(updateGlobalFilter({...globalFilter,[activeMenu]:{}}));
    //     }
    // },[activeMenu]);
    //set active menu
    useEffect(()=>{
        setActiveMenu(getActiveMenuByPath(pathname)); 
    },[pathname]); 
    //filter update in redux  
    const submitHandler=(filter)=>{
        dispatch(updateGlobalFilter({...globalFilter,[activeMenu]:filter}));
        setIsOpen(false);
          
      }
      //onchage for filter data
      const filterOnchangeHandler=(fieldName,val)=>{
        setFilterData({...filterData,[fieldName]:val});
      }
      const clearFilterHandler = () => {
        setFilterData({});
        dispatch(updateGlobalFilter({...globalFilter,[activeMenu]:{}})); 
      };
    const filterComponents = {
        'Visits': EmployeeVisitFilter,
        'White Board': WhiteBoardFilter,
        'Appointments': WhiteBoardFilter, 
        'Labs': LabResultFilter,
        'Vaccine': VaccineResultFilter,
        'Physicals': PhysicalsResultFilter,
        'Chart': EmployeeChartFilter
    };
    const FilterComponent = filterComponents[activeMenu]; //get fil-compo based on activeMenu
    // console.log('filt',activeMenu);
    
    return (
        <>
            <><ul className="form-group ml-auto mb-0">
                <>
                    <GlobalSearchInput 
                    toggleSearch={toggleSearch} 
                    isOpen={isOpen} 
                    activeMenu={activeMenu}
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm} 
                    clearFilterHandler={clearFilterHandler}
                    globalFilter={globalFilter[activeMenu]} 
                    submitHandler={submitHandler} />
                </>
            </ul>
                <TransitionGroup>
                    {
                        FilterComponent && FilterComponent ? (
                            <>
                            <FilterComponent
                                isOpen={isOpen}
                                filterData={filterData}
                                submitHandler={submitHandler}
                                onChangeHandler={filterOnchangeHandler}
                                clearFilterHandler={clearFilterHandler}
                                toggleSearch={toggleSearch}
                            />
                            {isOpen===true && <div onClick={() => toggleSearch()} style={{position:'fixed',top:'0',left:'0', zIndex:'1040',width: '100vw',height: '100vh'}}></div>}
                            </>
                        )
                            :
                            (<></>)
                    }
                </TransitionGroup>
            </>
        </>
    );
};
function getActiveMenuByPath(path){
    switch (path) {
        case '/visits':
            return 'Visits';
        case '/white-board':
            return 'White Board'
        case '/appointments':
            return 'Appointments';
        case '/labs':
            return 'Labs';
        case '/result/vaccine':
            return 'Vaccine';
        case '/result/physical-drug-screens':
            return 'Physicals';
        case '/employee':
            return 'Chart';
        default:
            if (path.startsWith('/result/physical-drug-screens/')) {
                return 'Physicals';
            }
            return '';
    }
}
export default withReduxState(GlobalSearch); 